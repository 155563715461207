export const environment = {
	production: true,
	useEmulators: false,
	firebase: {
		apiKey: 'AIzaSyBbQVDLj6Dw2wQgOb6uKGXYCUvtQmgmKP8',
		authDomain: 'plex.amiti.online',
		databaseURL: 'https://amiti-plex-live.firebaseio.com',
		projectId: 'amiti-plex-live',
		storageBucket: 'amiti-plex-live.appspot.com',
		messagingSenderId: '314768910401',
	},
	product: 'plex',
	admin: 'https://plex.amiti.online',
	client: 'amiti.online',
	olympus: 'olympus.amiti.online',
	googleMapAPI: 'AIzaSyDrcFqXnNtVFouynraq3pKar2rbo-cLZ1o',
	port: 8005,
	useSentry: false,
	region: 'us-central1',
};
